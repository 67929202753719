@font-face {
  font-family: 'Montserrat';
  src: url("./Montserrat-Regular.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./Montserrat-Bold.otf") format('opentype');
  font-weight: 900;
  font-style: normal;
}

:root {
  --color-off-white: #99A7C0;
  --color-metal-1: #475060;
  --color-metal-2: #2A3138;
  --color-platform-apple: #FF407B;
  --color-background: #1B2024;
  --color-inactive: var(--color-metal-1);

  --color-box-background: var(--color-metal-2);
  --color-box-border: var(--color-metal-1);
}

html {
  background-color: var(--color-background);
  color: white;
  height: 100%;
}

body {
  font-family: 'Montserrat';
  font-weight: 100;
  height: 100%;
}

#root {
  height: 100%;
}

div.App {
  padding: 0;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
  color: white;
}

header.App-header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  padding: 48px 48px 0 48px;
  background-color: var(--color-background);
}

header nav.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 12px;
}

nav.main {
  font-size: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: var(--color-inactive);
}

nav.main .active {
  color: white;
}


header.App-header .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

header.App-header .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -10px;
}

header.App-header .header-button {
  margin: 0 10px;
}

header.App-header .logo .row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

header.App-header h1 {
    font-size: 36px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--color-platform-apple);
}

main {
  flex: 1;
  padding: 0 72px 48px 72px;
}

nav.weeks {
  display: flex;
  align-items: stretch;
  margin: 12px 0;
  padding: 0;
  height: 62px;
}

nav.weeks a {
  margin: 0 24px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
 }

nav.weeks a:hover {
  border-bottom-color: white;
}

.loading-message {
  font-size: 41px;
  font-weight: bold;
  padding: 70px;
  text-align: center;
}

table {
  width: 100%;
  height: 100%;
  table-layout: auto;
  border-spacing: 0 0;
  border-collapse: collapse;
}

table caption {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

table th {
  text-align: left;
  font-size: 18px;
  color: var(--color-white);
  padding: 12px 0;
}

table thead tr {
  border-bottom: 1px solid var(--color-box-border);

}

table td {
  font-size: 24px;
}

.track-row {
  vertical-align: middle;
}

.track-row-link {
  display: contents;
}

td.track {
}

td.artist {
  vertical-align: middle;
}

td.changePercentage {
  text-align: right;
}

table col.streams-graph {
  width: 50%;
}

table col.streams-number {
  width: 10%;
}

table thead th.streams-graph {
  text-align: center;
}

table thead th.streams-graph .highlight {
  color: var(--color-platform-apple)
}

.category {
  margin: 24px 0;
  scroll-margin-top: 200px;
}

.category h2 {
  font-size: 42px;
  margin: 0;
  margin-bottom: 16px;
}

.category h3 {
  font-size: 24px;
  margin: 16px 0;
}

/* BAR GRAPH */
.bar-graph {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bar-graph .bar-graph-bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2px;
}

.bar-graph .bar-graph-bar {
  background-color: var(--color-off-white);
  border-radius: 16px 16px 0 0;
}

/* LINE GRAPH */

.line-graph {
  /*
  height: 100px;
  width: 600px;
  */
}


/* PRODUCT / TRACK IMAGE*/

div.product-image-container {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  border: 2px solid var(--color-box-border);
  vertical-align: middle;
  margin-right: 24px;
  overflow: hidden;
  display: inline-block;
  flex-shrink: 0;
}

img.product {
  width: 80px;
  height: 80px;
}


/* LOGIN **/
#login-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-box div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 284px;
}

#login-box h1 {
  font-size: 48px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

#login-box button {
  margin-top: 24px;
  font-size: 18px;
}

/* BUTTON */
button {
  background-color: #1B2024;
  border: 1px solid var(--color-box-border);
  border-radius: 12px;
  padding: 24px;
  color: white;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: var(--color-box-background);
}

/* FILTER */
.filter {
  margin-right: 24px;
  display: inline-block;
}

.filter select {
  margin-left: 8px;
}

/* LOGO */
img.logo {
  margin: 16px;
}

/* ACTVITIY INDICATOR */
div.activity-indicator {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(218, 180, 180, 0.0);
  border-right: 4px solid #ffffff;
  border-bottom:4px solid #ffffff;
  border-left: 4px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
