.wrapper {
  margin: 0;
  height: 100%;
  align-items: center;
  color: white;
  font-family: 'Montserrat', sans-serif;
  background-color: #121316;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.logo {
  width: 100%;
  height: auto;
  max-width: 105px;
}
.title {
  font-size: 36px;
  font-weight: 900;
}
.text {
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  text-align: center;
}
.store-links {
  margin-top: 30px;
}
.store-link {
  margin: 10px;
  display: flex;
  justify-content: center;
}
@media (max-width: 769px) {
  .top {
      margin: 0 15px;
  }
}
@media (min-width: 769px) {
  .store-links {
      display: flex;
  }
}
